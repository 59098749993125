const firebaseConfig = {
    apiKey: "AIzaSyC6odc2rl8o4jTllq9ymRBvMlxZAgO44QQ",
    authDomain: "aarb-testing.firebaseapp.com",
    projectId: "aarb-testing",
    storageBucket: "aarb-testing.appspot.com",
    messagingSenderId: "78881099467",
    appId: "1:78881099467:web:5b0d9bed430a9b0519554e",
    measurementId: "G-JG1VN5RNSQ"
  };

export default firebaseConfig;